import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Container,
  Typography,
  Button,
  Grid,
  Box,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import FormInput from "../form/FormInput";
import FormSelect from "../form/FormSelect";
import AxiosInstance from "../../utils/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import MuiAlert from "@mui/material/Alert";
import Loader from "../Loader.component";
import CryptoJS from "crypto-js";
import { encrypt } from "../../utils/cryptoHelper";
const PDFUploadForm = (props) => {
  const { control, handleSubmit, watch, setError, setValue, formState, reset } =
    useForm({
      mode: "onChange", // Enable reactivity on each change
    });
  const navigate = useNavigate();
  const { isDirty, isValid } = formState;
  const [dis46, setDis46] = useState(false);
  const [showYear, setShowYear] = useState(false);
  const [showQuarterSection, setShowQuarterSection] = useState(true);
  const [financialInformation, setFinancialInformation] = useState(false);
  const [years, setYears] = useState([]);
  const [quarter, setQuarter] = useState([]);
  const [currentYear, setSelectedYear] = useState("");
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [section, setSection] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setIsError] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [file, setFile] = useState({});
  const [currentMenuData, setCurrentMenuData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const menuItems = props.menuItems;
  const subMenuItems = props.subMenuItems;
  const quarterlyData = props.quarterData;
  const mapItems = (items) => {
    return items.map((item, index) => {
      const { text, type, ...rest } = item;
      return {
        label: text,
        value: text,
        type: type,
        ...rest,
      };
    });
  };

  const mapYears = (items) => {
    return items.map((item, index) => {
      return {
        label: item,
        value: item,
      };
    });
  };

  const filteredMenuData = menuItems.filter(
    (item) => !["NA", "PDF", "pageLink"].includes(item.type)
  );

  const getThreeYear = () => {
    // Get the current date
    const currentDate = new Date();

    // Get the current year
    const currentYear = currentDate.getFullYear();

    // Calculate the previous and next years
    const previoustwoYear = currentYear - 2;
    const previousYear = currentYear - 1;
    const nextYear = currentYear + 1;

    // Create the formatted strings
    setYears([
      `${previoustwoYear}-${previousYear}`,
      `${previousYear}-${currentYear}`,
      `${currentYear}-${currentYear + 1}`,
      // `${nextYear}-${nextYear + 1}`
    ]);
  };

  const onSubmit = async (data) => {
    try {
      const isWhitespace = /^\s*$/.test(data.title);
      if (isWhitespace) {
        setResponseMessage("Title cannot be empty or contain only whitespace");
        setIsError(true);
        setOpen(true);
      } else {
        setIsLoading(true);
        const formData = new FormData();
        const fData = {
          title: data.title,
          section: section,
          year: currentYear,
          quarter: selectedQuarter,
          fileName: file.name,
        };
        formData.append("data", encrypt(fData));
        formData.append("pdfFile", file);
        const postDetails = await AxiosInstance.post("/upload/pdf", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (postDetails.status === 201) {
          setResponseMessage(postDetails?.data?.message);
          setOpen(true);
          setIsLoading(false);
          setTimeout(() => {
            navigate(0);
          }, 5000);
        } else {
          setResponseMessage(
            postDetails?.data?.message ||
              postDetails?.response?.data?.error ||
              "Something went wrong"
          );
          setIsError(true);
          setOpen(true);
          setIsLoading(false);
        }
        const inputFile = document.getElementById("pdfFile");
        if (inputFile) {
          inputFile.value = "";
        }
        reset();
      }
    } catch (error) {
      setResponseMessage(
        error?.message || error.response?.data?.error || "Something went wrong"
      );
      setIsError(true);
      setOpen(true);
      reset();
      setIsLoading(false);
    }
  };

  const getQuarterDetails = (currentSelectedYear) => {
    const findData = currentMenuData?.find(
      (item) => item?.year === currentSelectedYear
    );
    // If the item is found, map through its "quarters" array
    const quartersArray = findData ? findData?.quarters : [];
    const results = Array.from({ length: 4 }, (_, index) => {
      // Check if the quarter at the current index exists in the quartersArray
      const currentQuarter = quartersArray[index];
      const isPresent = !!currentQuarter;

      // Return an object indicating the quarter name and whether it is present or not
      return {
        label: `Q${index + 1}`,
        value: `Q${index + 1}`,
        isDisabled: isPresent,
      };
    });
    return results;
  };

  const handleSelectChange = (selectedItem, selectedMenu, selectedData) => {
    setValue(selectedMenu, selectedItem, selectedData);
    // console.log(!isDirty,"is dirty", !isValid, "isvalid" ,"in menu")
    if (selectedMenu === "mainMenu") {
      setShowYear(false);
      setDis46(selectedItem === "Disclosures under Regulation 46 of the LODR");
      if (selectedItem !== "Disclosures under Regulation 46 of the LODR") {
        setSelectedYear("");
        setSelectedQuarter("");
        setValue("menuList", " ");
      }
      setFinancialInformation(false);
      setSection(selectedData?.heading || selectedItem);
    }

    if (selectedMenu === "menuList") {
      setFinancialInformation(
        selectedItem === "Financial information including:"
      );
      if (selectedItem !== "Financial information including:") {
        setSelectedYear("");
        setSelectedQuarter("");
      }
      // if(watch("submenu") !== ""  ){
      //   setShowYear(true)
      // }else{

      //   setShowYear(false);
      // }
      setShowYear(["VTN", "TBQData"].includes(selectedData?.type));
      setShowQuarterSection(!["VTN"].includes(selectedData?.type));
      setSection(selectedData?.heading || selectedItem);
    }
    if (selectedMenu === "submenu") {
      setShowYear(["VTN", "TBQData"].includes(selectedData?.type));
      setShowQuarterSection(!["VTN"].includes(selectedData?.type));
      setSection(selectedData?.heading || selectedItem);
    }
    if (selectedMenu === "year") {
      setSelectedYear(selectedItem);
      setShowYear(true);

      const results = getQuarterDetails(selectedItem);
      setQuarter(results);
    }

    if (selectedMenu === "quarter") {
      setSelectedQuarter(selectedItem);
    }

    if (
      !["year", "quarter"].includes(selectedMenu) &&
      ["VTN", "TBQData"].includes(selectedData?.type)
    ) {
      let convertedData = Object.keys(
        quarterlyData[selectedData?.heading] || {}
      ).map((year) => ({
        year,
        quarters: (quarterlyData[selectedData?.heading][year]?.files || []).map(
          (file, index) => ({
            ...file,
            quarter: `Q${index + 1}`,
            displayableName:
              file.nameToBeDisplayed?.name || "Name not available",
            link: file.name,
          })
        ),
      }));
      setCurrentMenuData(convertedData);

      const calculatedYears = convertedData?.map((item) => {
        const [startYear, endYear] = item.year.split("-");
        const newStartYear = (parseInt(startYear) + 1).toString();
        const newEndYear = (parseInt(endYear) + 1).toString();
        return `${newStartYear}-${newEndYear}`;
      });
      const lastThreeYears = calculatedYears?.slice(-3) || [];

      getThreeYear();
      setShowYear(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsError(false);
    setOpen(false);
  };

  return (
    <>
      {isLoading && <Loader />}
      {open && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={`${error ? "error" : "success"}`}
            sx={{ width: "100%" }}
          >
            {responseMessage || "Something went wrong"}
          </Alert>
        </Snackbar>
      )}

      {/* <Paper elevation={3} sx={{ padding: 2, marginBottom: 2, marginTop: 2 }}> */}
      <Typography variant="h4" gutterBottom marginBottom={"20px"}>
        Add File
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormInput
              name="title"
              control={control}
              defaultValue="" // Set an empty string
              fullWidth
              label="Title"
              variant="outlined"
              onChange={(e) => {
                let value = e;
                // Remove special characters and allow only one space between words
                const sanitizedValue = value
                  .replace(/[^a-zA-Z0-9().,/_-]+/, " ")
                  .replace(/\s+/g, " ")
                  .trimStart();

                // Limit the input to 150 characters
                value = sanitizedValue.substring(0, 150);
                const isValid = value.length > 0 && /[a-zA-Z0-9]/.test(value);
                setValue("title", value, { shouldDirty: isValid });
              }}
              onBlur={(e) => {
                const value = e?.target?.value;

                // Remove leading and trailing whitespace
                const sanitizedValue = value.trim();
                const isValid = /[a-zA-Z0-9]/.test(sanitizedValue);
                // setValue("title", sanitizedValue, { shouldDirty: isValid });
                // setValue("title", sanitizedValue, { shouldDirty: true });
              }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormSelect
              name="mainMenu"
              control={control}
              // setValue={setValue}
              // useForm={useForm}
              defaultValue="" // Set an empty string
              label={"Main Menu"}
              options={[
                {
                  label: "Disclosures under Regulation 46 of the LODR",
                  value: "Disclosures under Regulation 46 of the LODR",
                },
                {
                  label: "Other Compliance",
                  value: "Other Compliance",
                  type: "LOPDF",
                },
                {
                  label: "Shareholders' Information",
                  value: "Shareholders' Information",
                  type: "LOPDF",
                },
                {
                  label: "Guiding Polices",
                  value: "Guiding Polices",
                  type: "LOPDF",
                },
                {
                  label: "Open Offer",
                  value: "Open Offer",
                  type: "LOPDF",
                },
              ]}
              onChange={handleSelectChange}
            />
          </Grid>

          {dis46 && (
            <Grid item xs={12}>
              <FormSelect
                control={control}
                // setValue={setValue}
                // useForm={useForm}
                name="menuList"
                defaultValue=""
                label="Disclosure under Regulation of the LODR List"
                options={mapItems(filteredMenuData)}
                onChange={handleSelectChange}
              />
            </Grid>
          )}
          {financialInformation && (
            <Grid item xs={12} sm={4}>
              <FormSelect
                control={control}
                // setValue={setValue}
                // useForm={useForm}
                name="submenu"
                defaultValue=""
                label="Sub Menu"
                options={mapItems(subMenuItems)}
                onChange={handleSelectChange}
              />
            </Grid>
          )}
          {showYear && (
            <>
              <Grid item xs={12} sm={4}>
                <FormSelect
                  name="year"
                  control={control}
                  // setValue={setValue}
                  // useForm={useForm}
                  defaultValue=""
                  label={"Year"}
                  options={mapYears(years)}
                  onChange={handleSelectChange}
                />
              </Grid>
              {showQuarterSection && (
                <Grid item xs={12} sm={4}>
                  <FormSelect
                    name="quarter"
                    control={control}
                    // setValue={setValue}
                    // useForm={useForm}
                    defaultValue=""
                    label={"Quarter"}
                    options={quarter}
                    onChange={handleSelectChange}
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12} sm={6}>
            <Controller
              name="pdfFile"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <input
                    type="file"
                    accept=".pdf"
                    id="pdfFile"
                    required
                    {...field}
                    onChange={async (e) => {
                      if (e.target.files[0]) {
                        const selectedFile = e.target.files[0];
                        let errorMessage = null;

                        // Check if the file type is not PDF
                        if (selectedFile?.type !== "application/pdf") {
                          errorMessage = "Only PDF files are allowed";
                        }

                        // Check if the file name contains forbidden characters
                        const forbiddenCharactersPattern = /[?$%#@<>{}[\]]/;
                        if (
                          forbiddenCharactersPattern.test(selectedFile?.name)
                        ) {
                          errorMessage =
                            "File name with these ?$%#@<>{}[] special characters are not allowed";
                        }
                        // Check if the file size exceeds 15 MB
                        if (selectedFile?.size > 15 * 1024 * 1024) {
                          errorMessage = "File size should not exceed 15 MB.";
                        }
                        // Handle error message and reset the input field and state accordingly
                        if (errorMessage) {
                          field.onChange(""); // Set the input value to an empty string
                          setIsError(true);
                          setResponseMessage(
                            errorMessage || "Please select proper file"
                          );
                          setOpen(true);
                          setFile(null);
                        } else {
                          // If everything is valid, update the state variable
                          console.log(selectedFile, "file");
                          field.onChange(e);
                          setFile(selectedFile);
                        }
                      } else {
                        // If no file is chosen, reset the input value and the state variable
                        field.onChange("");
                        setFile(null);
                      }
                    }}
                  />
                  {!file?.name && (
                    <p className="file-upload-guidelines">
                      Only PDF files are allowed. <br />
                      Maximum file size is 15 MB. <br />
                      File name should not contain special characters such as{" "}
                      {"?$%#@<>{}[]"}.
                    </p>
                  )}
                </div>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isDirty || !isValid}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      {/* <iframe src="http://localhost:3000/about-us" frameborder="0" width={"100%"}></iframe> */}
      {/* </Paper> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    menuItems: state.LandingReducer.menuItems,
    subMenuItems: state.LandingReducer.subMenuItems,
    quarterData: state.LandingReducer.categories,
  };
};

export default connect(mapStateToProps)(PDFUploadForm);
